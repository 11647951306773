import "./PatternLateral.css"
import React from "react"

import TerritoryContext from "../Layout"
import PatternArt from "../../svg/patterns/patternArt.svg"
import PatternTech from "../../svg/patterns/patternTech.svg"
import PatternCare from "../../svg/patterns/patternCare.svg"
import PatternTribu from "../../svg/patterns/patternTribu.svg"

export default function PatternLateral({ territory }) {
  // TO-DO Cambiar en función del territorio en React.Context (APLICABLE A TODO EL LAYOUT COLORES ETC)
  // Hay que exportar correctamente el TerritoryContext de Layout
  // const territory = React.useContext(TerritoryContext)
  // console.log(territory)

  const patternSwitcher = territoryVar => {
    switch (territoryVar) {
      case "art":
        return <PatternArt />
      case "tech":
        return <PatternTech />
      case "care":
        return <PatternCare />
      default:
        return <PatternTribu />
    }
  }
  return (
    <div className="patternLateral">
      <div>{patternSwitcher(territory)}</div>
      <div>{patternSwitcher(territory)}</div>
    </div>
  )
}
