import "../portfolioPage.css"

import { graphql } from "gatsby"
import React from "react"
import { Trans } from "react-i18next"
import { useTranslation } from "react-i18next"

import Layout from "../../../components/Layout"
import Seo from "../../../components/Seo"
import PatternLateral from "../../../components/patternLateral/PatternLateral"
import VimeoVideo from "../../../components/portfolio/VimeoVideo"

import LogoTribu from "../../../svg/portfolio/logoTribu.svg"

export default function IndexPage({ data }) {
  const { t } = useTranslation()

  return (
    <Layout territory={"art"}>
      <Seo title={"Studio reel"} />

      <PatternLateral territory="art" />
      <h2 className="sectionTitle basic">
        <LogoTribu className="portfolioFileTitle" id="logoTribu" />
      </h2>

      <div className="content portfolioFileContent">
        <div className="filmDetails">
          <span>
            <Trans>Feature films, Shorts & more</Trans>
          </span>
          <span className="year">
            <Trans>2017 to 2020</Trans>
          </span>
        </div>

        <VimeoVideo
          videoId="691831862"
          title={t("Video reel of La Tribu Animation studio")}
          autoplay={false}
        >
          <p>
            <Trans>La Tribu Animation video reel</Trans>
          </p>
        </VimeoVideo>

        <p className="mainText disclaimer" id="formTop">
          <Trans>
            *Animal Crackers was co-directed and co-produced by Jaime Maestro
            (La Tribu Animation founder and director). The film was fully
            produced at the extinct Blue Dream Studios Spain, a joint venture
            with Blue Dream Studios (Scott Christian Sava) in which Jaime
            Maestro was co-founder, director and producer.
          </Trans>
        </p>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
