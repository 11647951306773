import "./EmbedVideo.css"
import React from "react"

export default function VimeoVideo({
  children,
  videoId,
  title,
  autoplay,
  aspectRatio,
}) {
  const playerColor = "ae3517"
  return (
    <div className="embedVideo">
      <div
        style={{
          padding: `${aspectRatio * 100 || "56.25"}% 0 0 0`,
          position: "relative",
        }}
      >
        <iframe
          src={
            `https://player.vimeo.com/video/` +
            videoId +
            `?dnt=1&color=${playerColor}&title=0&byline=0&portrait=0` +
            (autoplay ? "&autoplay=1&muted=1&loop=1" : "")
          }
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          }}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          title={title}
        ></iframe>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>

      {children}
    </div>
  )
}
